import logo from './backchannel-logo-1.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <span className="logoContainer"><img src={logo} className="App-logo" alt="logo" /></span>
      <span className='title'>Backchannel</span>
      <span className='subtitle'>A tech talent network run by CEOs, CPOs, CTOs, and ex-founders (not recruiters)</span>
      <br />
      <span className='promoLinks'>Learn more: <a target='BLANK' href='https://docsend.com/view/yaue7hznz82b7jmm'>Startups</a> &#x1F91D; <a target='BLANK' href='https://docsend.com/view/nxuzmbih52vi8pim'>Talent</a></span>

      <div className='pageDetails'>
      <br />
      Focus Areas: 1. 1st PM 2. Early engineers 3. Early designers 4. AI/ML/NLP Talent 5. Executive Search
      <br /><br />

    Startups: Stop sending cold Linkedin messages. It's so painful. We'll help you hire the best talent in the world.
    <br />    <br />
    Talent: You might be a 10X engineer but a 0.1X job seeker. We'll help you find your dream role with our insider network.
<br /><br />
Network: We are 100+ executives in Silicon Valley who actually know how hard it is to be a founder and build world-class teams. We've hired and led teams at Google, Facebook, Amazon, Microsoft, Netflix -- and more importantly, the early hypergrowth teams at Stripe, Slack, Notion, Airtable, Snowflake, Databricks, Airbnb, Uber, Snapchat, Doordash, Pinterest, Coinbase, Palantir, Instacart -- and most importantly, failed startups where we chewed glass. We will have your back and care. Ready to go? concierge@backchannel.cc
</div>
<br />
      </header>
    </div>
  );
}

export default App;
